/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'question_mark': {
    width: 10,
    height: 21,
    viewBox: '0 0 10 21',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.117 12.969l.226-1.924c.046-.378.213-.716.485-1.01l.004-.005c.258-.267.557-.532.898-.795l.003-.002c.341-.256.706-.526 1.095-.81.369-.28.708-.605 1.016-.977.311-.373.566-.806.764-1.302l.002-.004c.2-.478.305-1.059.305-1.751 0-.674-.126-1.272-.371-1.797a3.967 3.967 0 00-1.022-1.354 4.683 4.683 0 00-1.568-.87A6.065 6.065 0 004.971.057c-.55 0-1.058.055-1.527.163H3.44c-.465.1-.9.242-1.308.422a7.416 7.416 0 00-1.119.628 8.79 8.79 0 00-.76.598l.543.868c.085.14.182.19.31.19a.193.193 0 00.066-.023.977.977 0 00.18-.124l.005-.005c.193-.152.436-.315.726-.49.305-.182.67-.346 1.09-.493h.001c.454-.155.984-.229 1.583-.229.454 0 .882.066 1.283.2.41.133.769.33 1.074.592.306.254.548.565.726.93.181.375.27.787.27 1.233 0 .603-.116 1.142-.358 1.606-.222.444-.51.842-.862 1.194-.333.333-.694.641-1.085.924l-.001.001c-.364.261-.71.527-1.036.798-.304.25-.555.52-.757.809a1.527 1.527 0 00-.262.89v.145l.15 2.085h1.217zm-1.746 6.89c.132.13.287.232.468.302l.005.002.005.002c.18.079.374.119.583.119.208 0 .402-.04.583-.119l.004-.002.005-.002a1.389 1.389 0 00.778-.766l.003-.006c.078-.179.118-.377.118-.597 0-.208-.04-.402-.118-.582l-.002-.005L5.8 18.2a1.354 1.354 0 00-.306-.47 1.538 1.538 0 00-.48-.325 1.444 1.444 0 00-.583-.118c-.209 0-.402.04-.583.118-.185.08-.343.188-.478.321a1.677 1.677 0 00-.31.479 1.61 1.61 0 00-.106.587c0 .221.036.42.105.6.081.177.185.332.311.467z" _fill="#898B9B"/>'
  }
})
